<!-- <ion-list>
  <ion-list-header>Notifications</ion-list-header>

  <div *ngIf="(messageArray | async)?.length > 0; else noNotifications">
    <ion-item *ngFor="let notification of messageArray | async; let i = index" (click)="clickNotification(notification?.data, i)">
      <ion-avatar slot="start">
        <ion-icon name="notifications" class="notification-icon"></ion-icon>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h3>{{ notification.notification.title }}</h3>
        <p>{{ notification.notification.body }}</p>
      </ion-label>
    </ion-item>
  </div>
  <ng-template #noNotifications>
    <ion-item lines="none">
      <ion-text color="medium"><span>No notifications...</span></ion-text>
    </ion-item>
  </ng-template>
</ion-list> -->

<ion-list lines="full">
  <ion-list-header>
    <ion-label>
      <div>Notifications</div>
      <div><a class="view-all" (click)="viewAllNotifications()">View all</a></div>
    </ion-label>
  </ion-list-header>

  <div class="ion-text-center">
    <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
  </div>

  <div *ngIf="notifications?.length > 0 && !isLoading" class="list-container">
    <ng-container *ngFor="let notification of notifications; let i = index">
      <ion-item (click)="clickNotification(notification, i)">
        <ion-avatar slot="start">
          <ion-icon name="notifications" class="notification-icon"></ion-icon>
        </ion-avatar>
        <div class="notification-body-container">
          <ion-label class="ion-text-wrap">
            <h3>{{ notification.notification.title }}</h3>
            <p>{{ notification.notification.body }}</p>
          </ion-label>

          <span class="notification-time">{{ moment(notification.created_at).format("MM/DD/YY, hh:mm A") }}</span>
        </div>
      </ion-item>
    </ng-container>
  </div>
  <div *ngIf="!(notifications?.length > 0) && !isLoading">
    <ion-item lines="none">
      <ion-text color="medium"><span>No notifications...</span></ion-text>
    </ion-item>
  </div>
</ion-list>