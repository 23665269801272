import { NgModule } from "@angular/core";
import { HtmlLinksPipe } from "./html-links/html-links.pipe";
import { ShortNumberPipe } from "./short-number/short-number.pipe";

@NgModule({
  declarations: [HtmlLinksPipe, ShortNumberPipe],
  imports: [],
  exports: [HtmlLinksPipe, ShortNumberPipe],
})
export class PipesModule {}
