import { Injectable } from "@angular/core";
import { ApiService } from "../../../services/api/api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";

import {
  DepartmentAccess,
  DocumentCheckResponse,
  GetUserDocCheckRequestParameters,
  GetUserRequestParameters,
  GetUsers,
  GetUsersRequestParameters,
  UserRequestBody,
} from "./models/users.model";
import { urls } from "./endpoints/users.endpoints";
import { setHttpParams } from "src/app/helpers/http-params.helper";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public user = new BehaviorSubject<GetUsers>(null);
  private users: BehaviorSubject<GetUsers[]> = new BehaviorSubject<GetUsers[]>([]);

  //Loading Observables
  usersLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _apiService: ApiService, private http: HttpClient) {}

  /**
   * @use {getAllUsers}
   * @deprecated
   */
  GetUsers(
    page?: number,
    limit?: number,
    search?: string,
    sortBy?: string,
    sortType?: string,
    user_role?: string,
    subcategory_id?: string,
    document_status?: string,
    documents_incomplete?: boolean,
    active_users = false
  ) {
    let queryString =
      `?page=${page}&limit=${limit}` +
      ((search ? `&search=${search}` : "") +
        (sortBy ? `&sortBy=${sortBy}` : "") +
        (sortType ? `&sortType=${sortType}` : "") +
        (user_role ? `&user_role=${user_role}` : "") +
        (subcategory_id ? `&subcategory_id=${subcategory_id}` : "") +
        (document_status ? `&document_status=${document_status}` : "") +
        (documents_incomplete ? `&documents_incomplete=${documents_incomplete}` : ""));

    if (active_users) {
      queryString += "&active_users=true";
    }

    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    //Start Loader
    this.setIsLoading("users", true);

    this.http
      .get<GetUsers[]>(endPoint, { headers: new HttpHeaders(headers) })
      .pipe(take(1))
      .subscribe((users) => {
        this.users.next(users);

        //End Loader
        this.setIsLoading("users", false);
      });
  }

  /**
   * GET request to get all users.
   *
   * @param params All params are optional.
   * @param active_users Defaults to false. Boolean of whether to return only active users.
   */
  getAllUsers(params: GetUsersRequestParameters, active_users = false) {
    let queryStringParams: HttpParams = setHttpParams(params);

    if (active_users) {
      queryStringParams = queryStringParams.append("active_users", `${active_users}`);
    }

    const endPoint = this._apiService.baseURL + urls.users;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    this.setIsLoading("users", true);

    this.http
      .get<GetUsers[]>(endPoint, {
        headers,
        params: queryStringParams,
      })
      .pipe(take(1))
      .subscribe((users) => {
        this.users.next(users);
        this.setIsLoading("users", false);
      });
  }

  getUser(user_id: string) {
    let queryString = `?user_id=${user_id}`;
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .get<GetUsers>(endPoint, { headers: new HttpHeaders(headers) })
      .toPromise();
  }

  /**
   * GET request specifically used for document details page to facilitate loading
   * between primary docs and other docs screens.
   *
   * @param params Params of type GetUserRequestParameters
   */
  getUserSubscription(params: GetUserRequestParameters) {
    const queryStringParams: HttpParams = setHttpParams(params);

    let endPoint = this._apiService.baseURL + urls.users;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    this.setIsLoading("users", true);

    this.http
      .get<GetUsers>(endPoint, { headers, params: queryStringParams })
      .pipe(take(1))
      .subscribe((user: GetUsers) => {
        this.user.next(user);
        this.setIsLoading("users", false);
      });
  }

  /**
   * GET request to check if a user has all uploaded docs required by platform.
   *
   * @param params Params of type GetUserDocCheckRequestParameters
   * @returns Promise of type DocumentCheckResponse
   */
  getUserDocCheck(
    params: GetUserDocCheckRequestParameters
  ): Promise<DocumentCheckResponse> {
    let queryStringParams: HttpParams = setHttpParams(params);

    queryStringParams = queryStringParams.append("docs_check", "true");

    let endPoint = this._apiService.baseURL + urls.documents;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http
      .get<DocumentCheckResponse>(endPoint, {
        headers,
        params: queryStringParams,
      })
      .toPromise();
  }

  DeleteUser(user_id: string, mpDelete = false) {
    let queryString = `?user_id=${user_id}`;

    if (mpDelete) {
      queryString += "&requestedBy=mp";
    }

    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "text",
    };

    return this.http
      .delete(endPoint, { headers: new HttpHeaders(headers), responseType: "text" })
      .toPromise();
  }

  /**
   * Endpoint to deactivate an MP user. This will set `is_active` to FALSE and return all
   * of the users accepted shifts to a created status.
   *
   * @param user_id MP user_id
   */
  DeactivateUser(user_id: string): Promise<string> {
    let queryString = `?deactivate=1&user_id=${user_id}`;

    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "text",
    };

    const body = null;

    return this.http
      .patch(endPoint, body, { headers: new HttpHeaders(headers), responseType: "text" })
      .toPromise();
  }

  /**
   * This endpoint will simply flip the user's `is_active` flag rendering them deactivated. The user will no longer
   * be able to login.
   *
   * @param user_id
   * @param action set `is_active` to true or false
   */
  BlockUser(user_id, action) {
    let queryString = `?user_id=${user_id}`;
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    const body = {
      isActive: action,
    };

    return this.http
      .patch(endPoint, body, { headers: new HttpHeaders(headers), responseType: "text" })
      .toPromise();
  }

  AddUser(body: UserRequestBody, medical_facility_id?: string) {
    let queryString =
      `?admin=true` +
      (medical_facility_id ? `&medical_facility_id=${medical_facility_id}` : "");
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post(endPoint, body, { headers: new HttpHeaders(headers), responseType: "text" })
      .toPromise();
  }

  EditUser(user_id: string, body: UserRequestBody, medical_facility_id?: string) {
    let queryString =
      `?user_id=${user_id}` +
      (medical_facility_id ? `&medical_facility_id=${medical_facility_id}` : "");
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .patch(endPoint, body, { headers: new HttpHeaders(headers), responseType: "text" })
      .toPromise();
  }

  getUserMessages(medical_professional_id: string) {
    let queryString = `?admin_mp=true&medical_professional_id=${medical_professional_id}`;
    let endPoint = this._apiService.baseURL + urls.messages + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http.get(endPoint, { headers: new HttpHeaders(headers) }).toPromise();
  }

  postUserMessages(medical_professional_id: string, body: any) {
    let queryString = `?admin_mp=true&medical_professional_id=${medical_professional_id}`;
    let endPoint = this._apiService.baseURL + urls.messages + queryString;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post(endPoint, body, { headers: new HttpHeaders(headers), responseType: "text" })
      .toPromise();
  }

  getDepartmentAccess(
    user_id: string,
    medical_facility_id: string
  ): Promise<DepartmentAccess> {
    if (!user_id || !medical_facility_id) {
      return;
    }

    const queryString = `?department_access=${user_id}&medical_facility_id=${medical_facility_id}`;
    const endPoint = this._apiService.baseURL + urls.users + queryString;

    const headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .get<DepartmentAccess>(endPoint, { headers: new HttpHeaders(headers) })
      .toPromise();
  }

  setDepartmentAccess(
    user_id: string,
    medical_facility_id: string,
    department_ids: string
  ): Promise<string> {
    const queryString = `?department_access=${user_id}&medical_facility_id=${medical_facility_id}&departments=${department_ids}`;
    const endPoint = this._apiService.baseURL + urls.users + queryString;

    const headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post(endPoint, null, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }

  public get users$(): Observable<GetUsers[]> {
    return this.users.asObservable();
  }

  //#endregion Getters for Observables & #region Loading Manager

  public get loader_users$(): Observable<boolean> {
    return this.usersLoading.asObservable();
  }

  public setIsLoading(type: string, value: boolean) {
    switch (type) {
      case "users":
        this.usersLoading.next(value);
    }
  }
}
