export const environment = {
  production: false,
  baseUrl: "https://fullrein.azure-api.net/staging/",
  firebaseConfig: {
    apiKey: "AIzaSyAMZc6BZr5R5ETqF2o1BOsMADNIx4-KRmE",
    authDomain: "fullrein-97449.firebaseapp.com",
    projectId: "fullrein-97449",
    storageBucket: "fullrein-97449.appspot.com",
    messagingSenderId: "687126086679",
    appId: "1:687126086679:web:105c02da31d4058e8ac852",
  },
  blobConfig: {
    accountName: "fullreindocuments",
    containerName: "fullrein-mf-logos",
    sasToken:
      "sp=racwdl&st=2021-07-15T10:32:03Z&se=2030-07-15T18:32:03Z&spr=https&sv=2020-08-04&sr=c&sig=cyN%2FZ6ZX2BoeGOWk%2Fj%2BEigE%2Bfy8j7vLCQqTdWaKT%2B7s%3D",
  },
  blobDocumentsConfig: {
    containerName: "fullrein-documents",
    sasToken:
      "sp=racwdl&st=2021-04-16T00:02:52Z&se=2030-01-01T08:02:52Z&spr=https&sv=2020-02-10&sr=c&sig=3oaZavBd5RGuyf%2FAQKwqa1lOIm%2B%2B%2F2n%2B00rB6xuL9kM%3D",
  },
};
