import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase/app";
import { BehaviorSubject } from "rxjs";

import { SigninService } from "../../BLL/signin/signin.service";
// import { SignupService } from "../../BLL/signup/signup.service";
// import { DocumentService } from "../../BLL/documents/documents.service";
import { ToastService } from "./../toast/toast.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // Save logged in user data
  userData: any;
  // Get user's Access Token
  accessToken: string;
  // Loader Subscription
  authIsLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private zone: NgZone,
    private _signInService: SigninService,
    // private _signUpService: SignupService,
    // private _documentService: DocumentService,
    private _toastService: ToastService
  ) {
    // Saving user data in localstorage when logged in and setting up null when logged out
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        // console.log("User in Local Storage", JSON.parse(localStorage.getItem("user")));
      } else {
        localStorage.setItem("user", null);
        localStorage.setItem("token", null);
        localStorage.setItem("user_db", null);
        // console.log("User in Local Storage", JSON.parse(localStorage.getItem("user")));
      }
    });
  }

  //=========================== SERVICE METHODS ================================//

  // Sign in with email/password
  async signIn(email: string, password: string) {
    this.authIsLoading.next(true);
    let result = await this.afAuth.signInWithEmailAndPassword(email, password);
    localStorage.setItem("user", JSON.stringify(result.user));
    let tokenDetails = await result.user.getIdTokenResult();
    localStorage.setItem("token", JSON.stringify(tokenDetails.token));
    // console.log("token", tokenDetails.token);
    try {
      //Hit API to check if user against this ID exists
      let resultAPI = await this._signInService.getUser(result.user.uid);

      if (resultAPI.category_type === "admin") {
        if (resultAPI.is_active) {
          if (resultAPI.user_role === "admin") {
            this.authIsLoading.next(false);
            // console.log("Logged In User", result.user);

            localStorage.setItem("user_db", JSON.stringify(resultAPI));
            this.router.navigate(["/admin"]);
          } else if (resultAPI.user_role === "manager") {
            this.authIsLoading.next(false);
            // console.log("Logged In User", result.user);

            localStorage.setItem("user_db", JSON.stringify(resultAPI));
            this.router.navigate(["/manager"]);
          }
        } else {
          this.authIsLoading.next(false);
          this._toastService.presentToast("you've been blocked !!", "danger");

          this.signOut();
        }
      } else {
        this.authIsLoading.next(false);
        this._toastService.presentToast(
          "Please enter credentials for either an Admin or a Manager",
          "danger"
        );

        this.signOut();
      }
      // if (resultAPI.user_role === "admin") {
      //   this.authIsLoading.next(false);
      //   console.log("Logged In User", result.user);
      //   localStorage.setItem("user_db", JSON.stringify(resultAPI));
      //   this.router.navigate(["/admin"]);
      // } else if (resultAPI.user_role === "manager") {
      //   this.authIsLoading.next(false);
      //   console.log("Logged In User", result.user);
      //   localStorage.setItem("user_db", JSON.stringify(resultAPI));
      //   this.router.navigate(["/manager"]);
      // }
    } catch (error) {
      this.authIsLoading.next(false);
      this._toastService.presentToast(error.message, "danger");
    }
  }

  // Sign Up with email/password
  // async signUp(
  // 	email: string,
  // 	full_name: string,
  // 	password: string,
  // 	profession: string,
  // 	state: string,
  // 	city: string,
  // 	zipCode: string,
  // 	date: Date,
  // 	gender: string
  // ) {
  // 	this.authIsLoading.next(true);
  // let result = await this.afAuth.createUserWithEmailAndPassword(email, password);
  // let tokenDetails = await result.user.getIdTokenResult();
  // try {
  // 	this.afAuth.currentUser
  // 		.then((u) => u.sendEmailVerification())
  // 		.then(() => {
  // 			this._signUpService
  // 				.registerUser(result.user.uid, profession, full_name, email, gender, date, state, city, zipCode)
  // 				.then((response) => {
  // 					console.log("Signed Up User", result.user);
  // 					console.log("res", response);
  // 					this._toastService.presentToast(response, "success");
  // 					this.authIsLoading.next(false);
  // 					this.router.navigate(["/admin/users"]);
  // 				});
  // 		});
  // } catch (error) {
  // 	this.authIsLoading.next(false);
  // 	console.log("Error", error.message);
  // 	this._toastService.presentToast(error.message, "danger");
  // }
  // }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("user_db");
      this.router.navigate([""]);
    });
  }
}
