import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";

import { NotificationsService } from "src/app/BLL/Admin/notifications/notifications.service";
import { UserRequestBody } from "src/app/BLL/Admin/users/models/users.model";
import { UsersService } from "src/app/BLL/Admin/users/users.service";
import { ToastService } from "../toast/toast.service";

@Injectable({
  providedIn: "root",
})
export class FcmService {
  //Current notification from Firebase
  currentMessage = new BehaviorSubject(null);
  notificationArray: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  userData: any;
  medicalFacility: any;

  constructor(
    private afAuth: AngularFireAuth,
    private messaging: AngularFireMessaging,
    private _notificationsService: NotificationsService,
    private _userService: UsersService,
    private _toastService: ToastService
  ) {}

  /**
   * Update the firebase token everytime it permission is granted
   * @param token the messaging token returned from firebase messaging
   */
  updateToken(token: string) {
    this.afAuth.authState.subscribe(async (user) => {
      if (!user) return;

      if (token) {
        localStorage.setItem("device_token", JSON.stringify(token));
        let userData = JSON.parse(localStorage.getItem("user"));

        //Body to update Admin device token
        let body: UserRequestBody = {
          fcm_token: token,
        };

        try {
          if (userData?.uid) {
            await this._userService.EditUser(userData?.uid, body);
          }
        } catch (error) {
          this._toastService.presentToast(error, "danger");
        }
      }
    });
  }

  /**
   * Request permission from user to receive notifications
   */
  requestPermission() {
    this.messaging.requestToken.subscribe(
      (token) => {
        console.log("device_token", token);
        this.updateToken(token);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  /**
   * Receive notifications and update the subscription
   */
  receiveMessage() {
    this.messaging.messages.subscribe(async (notification) => {
      console.log("new message received. ", notification);
      this.currentMessage.next(notification);
      this.updateNotificationArray(notification);
      // let body = {
      //   title: notification["notification"]["title"],
      //   body: notification["notification"]["body"],
      //   deeplink: notification["data"]["deeplink"],
      // };
      // try {
      //   let response = await this._notificationsService.postNotifications(true, body);
      // } catch (error) {
      //   this._toastService.presentToast(error, "danger");
      // }
    });
  }

  /**
   * Update the notification array
   * @param notification The new notification
   */
  updateNotificationArray(notification) {
    const currentNotification = this.notificationArray.value;
    // const updatedArray = [...currentNotification, notification];
    const updatedArray = [notification, ...currentNotification];
    console.log("notification array", updatedArray);
    this.notificationArray.next(updatedArray);
  }

  clearNotification(index: number) {
    let currentNotificationArray = this.notificationArray.value;
    currentNotificationArray.splice(index, 1);
    this.notificationArray.next(currentNotificationArray);
  }

  clearAllNotifications() {
    this.notificationArray.next([]);
  }
}
