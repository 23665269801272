import { HttpParams } from "@angular/common/http";

/**
 * @param params Any object that needs to be serialized.
 * @returns An HTTP request body that represents serialized parameters, per the MIME type application/x-www-form-urlencoded.
 */
export const setHttpParams = (params: any): HttpParams => {
  let queryStringParams = new HttpParams();

  if (params) {
    Object.keys(params).forEach((key) => {
      queryStringParams = queryStringParams.append(key, params[key]);
    });
  }

  return queryStringParams;
};
