import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/api/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//Data Models
import { post_notification } from "./models/notifications.model";

//Endpoints
import { urls } from "./endpoints/notifications.endpoints";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private _apiService: ApiService, private http: HttpClient) {}

  getNotifications(admin: boolean) {
    let queryParam = `?admin=${admin}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http.get(endPoint, { headers: new HttpHeaders(headers) }).toPromise();
  }

  postNotifications(admin: boolean, body: post_notification) {
    let queryParam = `?admin=${admin}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post(endPoint, body, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }

  deleteNotifications(admin: boolean) {
    let queryParam = `?admin=${admin}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .delete(endPoint, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }

  deleteNotificationById(notificationId: string, admin: boolean) {
    let queryParam = `?notification_id=${notificationId}&admin=${admin}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .delete(endPoint, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }
}
