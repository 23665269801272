import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { UserPopoverComponent } from "./user-popover/user-popover.component";
import { NotificationListComponent } from "./notification-list/notification-list.component";
import { PipesModule } from "../pipes/pipes.module";

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, RouterModule, PipesModule],
  declarations: [NotificationListComponent, UserPopoverComponent],
  exports: [NotificationListComponent, UserPopoverComponent],
})
export class ComponentsModule {}
