import { Component, OnInit } from "@angular/core";
import { NavigationExtras } from "@angular/router";
import { NavController, PopoverController } from "@ionic/angular";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { NotificationsService } from "src/app/BLL/Admin/notifications/notifications.service";
import { FcmService } from "src/app/services/fcm/fcm.service";
import { ToastService } from "src/app/services/toast/toast.service";

@Component({
  selector: "app-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrls: ["./notification-list.component.scss"],
})
export class NotificationListComponent implements OnInit {
  userData: any;
  isLoading: boolean = false;
  //Moment
  moment: any = moment;

  //Firebase notification
  messageArray: BehaviorSubject<Array<any>>;
  notifications: any;
  constructor(
    private fcmService: FcmService,
    private nav: NavController,
    private popCtrl: PopoverController,
    private _notificationsService: NotificationsService,
    private _toastService: ToastService
  ) {}

  async ngOnInit() {
    //Sync the behavior subject
    this.messageArray = this.fcmService.notificationArray;
    this.userData = JSON.parse(localStorage.getItem("user"));

    try {
      this.isLoading = true;
      this.notifications = await this._notificationsService.getNotifications(true);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this._toastService.presentToast(error, "danger");
    }
  }

  async clickNotification(item: any, index: number) {
    try {
      this.isLoading = true;

      await this._notificationsService.deleteNotificationById(item.id, true);

      this.fcmService.clearNotification(index);

      if (item.data?.deeplink) {
        this.nav.navigateForward(item.data?.deeplink);
      } else {
        this.nav.navigateForward("/admin/medical-professionals");
      }

      this.popCtrl.dismiss();
    } catch (error) {
      this._toastService.presentToast(error, "danger");
      this.popCtrl.dismiss();
    } finally {
      this.isLoading = false;
    }
  }

  viewAllNotifications() {
    this.fcmService.clearAllNotifications();

    let navigationExtras: NavigationExtras = {
      queryParams: {
        refreshToken: new Date().getTime(),
      },
    };

    this.nav.navigateForward("/admin/notifications", navigationExtras);
    this.popCtrl.dismiss();
  }
}
