import { NotificationListComponent } from "./components/notification-list/notification-list.component";
import { BehaviorSubject } from "rxjs";
import { FcmService } from "./services/fcm/fcm.service";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { AuthService } from "./services/authentication/auth.service";
import { UserPopoverComponent } from "./components/user-popover/user-popover.component";

interface AppPages {
  title: string;
  url: string;
  /**
   * @deprecated
   * Use `iconName` instead.
   */
  icon?: string;
  /**
   * @deprecated
   * Use `iconName` instead.
   */
  selectedIcon?: string;
  iconName: string;
  /**
   * Indicates that an admin role is needed to access the page.
   */
  adminRoleNeeded?: boolean;
  /**
   * Indicates that a manager or admin role is needed to access the page.
   */
  managerRoleNeeded?: boolean;
  /**
   * Disables navigation to the data section when inside an interior section of documents.
   */
  disabled?: boolean;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  userDb: any;

  activatedAppUrl: string;

  /**
   * Not fully implemented yet.
   */
  public appPages = [
    {
      title: "DASHBOARD",
      url: "/manager/dashboard",
      icon: "assets/pages/manager/dashboard/icon_dashboard_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_dashboard.png",
      iconName: "bar-chart-outline",
    },
    {
      title: "JOBS",
      url: "/manager/jobs",
      icon: "assets/pages/manager/dashboard/icon_jobs_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_jobs.png",
      iconName: "business-outline",
    },
    {
      title: "EARNINGS",
      url: "/manager/earnings",
      icon: "assets/pages/manager/dashboard/icon_earnings_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_earnings.png",
    },
    {
      title: "DOCUMENTS",
      url: "/manager/documents",
      icon: "assets/pages/manager/dashboard/icon_documents_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_documents.png",
    },
    {
      title: "REPORTS",
      url: "/manager/reports",
      icon: "assets/pages/manager/dashboard/icon_reports_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_reports.png",
    },
    {
      title: "ALERTS",
      url: "/manager/alerts",
      icon: "assets/pages/manager/dashboard/icon_alerts_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_alerts.png",
    },
  ];

  public adminPages: Array<AppPages> = [
    {
      title: "DASHBOARD",
      url: "/admin/dashboard",
      icon: "assets/pages/manager/dashboard/icon_dashboard_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_dashboard.png",
      iconName: "bar-chart-outline",
      disabled: false,
    },
    {
      title: "USERS",
      url: "/admin/users",
      icon: "assets/pages/admin/menubar/icon_users.png",
      selectedIcon: "assets/pages/admin/menubar/icon_users_active.png",
      iconName: "people-outline",
      disabled: false,
    },
    {
      title: "FACILITIES",
      url: "/admin/facilities",
      icon: "assets/pages/admin/menubar/icon_facility.png",
      selectedIcon: "assets/pages/admin/menubar/icon_facility_active.png",
      iconName: "business-outline",
      disabled: false,
    },
    {
      title: "MEDICAL PROF.",
      url: "/admin/medical-professionals",
      icon: "assets/pages/admin/menubar/icon_mp.png",
      selectedIcon: "assets/pages/admin/menubar/icon_mp_active.png",
      iconName: "people-outline",
      disabled: false,
    },
    // {
    // 	title: "REPORTS",
    // 	url: "/admin/reports",
    // 	icon: "assets/pages/admin/menubar/icon_reports.png",
    // 	selectedIcon: "assets/pages/admin/menubar/icon_reports_active.png",
    // },
    {
      title: "DOCUMENTS",
      url: "/admin/documents",
      icon: "assets/pages/manager/dashboard/icon_documents_inactive.png",
      selectedIcon: "assets/pages/manager/dashboard/icon_documents.png",
      iconName: "documents-outline",
      disabled: false,
    },
    {
      title: "PAYMENTS",
      url: "/admin/payments",
      iconName: "cash-outline",
      disabled: false,
    },
    {
      title: "DATA",
      url: "/admin/data",
      icon: "",
      iconName: "server-outline",
      disabled: false,
    },
  ];

  messageArray: BehaviorSubject<Array<any>>;

  constructor(
    private router: Router,
    private _authService: AuthService,
    private popCtrl: PopoverController,
    private fcmService: FcmService
  ) {
    this.router.events.subscribe((value) => {
      this.activatedAppUrl = router.url.toString();

      // Disables navigation to the data section when inside an interior section of documents.
      this.adminPages[6].disabled = this.activatedAppUrl.includes("document-detail");
    });
  }

  ngOnInit() {
    //Request permission to get notifications
    this.fcmService.requestPermission();
    //Receive the message
    this.fcmService.receiveMessage();
    //Sync the behavior subject
    this.messageArray = this.fcmService.notificationArray;
  }

  ngDoCheck() {
    if (JSON.parse(localStorage.getItem("user_db"))) {
      this.userDb = JSON.parse(localStorage.getItem("user_db"));
    }
  }

  logOut() {
    this._authService.signOut();
  }

  //Open user dropdown
  async openUserPopover(ev: any) {
    const popover = await this.popCtrl.create({
      component: UserPopoverComponent,
      event: ev,
    });

    popover.onDidDismiss().then((action) => {
      if (action.data && action.data === "logout") {
        this._authService.signOut();
      }
    });

    return await popover.present();
  }

  async openNotificationList(ev: any) {
    const popover = await this.popCtrl.create({
      component: NotificationListComponent,
      cssClass: "notification-popover",
      event: ev,
    });

    // popover.onDidDismiss().then((action) => {
    //   if (action.data && action.data === "logout") {
    //     this._authService.signOut();
    //   }
    // });

    return await popover.present();
  }
}
