import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { AngularFireAuthGuard, redirectLoggedInTo, canActivate, customClaims } from "@angular/fire/auth-guard";

const user = JSON.parse(localStorage.getItem("user_db"));

// AngularFireAuthGuard Pipe to redirect logged in User to admin or manager dashboard on empty route
const redirectLoggedInUser = () => {
	return user?.user_role === "admin" ? redirectLoggedInTo(["/admin"]) : redirectLoggedInTo(["/manager"]);
};

const managerOnly = () =>
	pipe(
		customClaims,
		map((claims) => claims.user_role === "manager")
	);

const adminOnly = () =>
	pipe(
		customClaims,
		map((claims) => claims.user_role === "admin")
	);

const routes: Routes = [
	{
		path: "",
		redirectTo: "auth/login",
		pathMatch: "full",
	},
	{
		path: "auth",
		loadChildren: () => import("./views/auth/auth.module").then((m) => m.AuthModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInUser },
	},
	{
		path: "manager",
		loadChildren: () => import("./views/manager/manager.module").then((m) => m.ManagerModule),
		...canActivate(managerOnly),
	},
	{
		path: "admin",
		loadChildren: () => import("./views/Admin/admin.module").then((m) => m.AdminModule),
		...canActivate(adminOnly),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
