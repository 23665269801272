import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-user-popover",
  templateUrl: "./user-popover.component.html",
  styleUrls: ["./user-popover.component.scss"],
})
export class UserPopoverComponent implements OnInit {
  //User dropdown action
  action: any;

  constructor(private popCtrl: PopoverController) {}

  ngOnInit() {}

  async logOut() {
    this.action = "logout";

    return this.popCtrl.dismiss(this.action);
  }
}
