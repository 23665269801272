import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { exhaustMap, take, tap } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // token: any = JSON.parse(localStorage.getItem("token"));
  // userData: any = JSON.parse(localStorage.getItem("user"));
  // token: any = this.userData?.stsTokenManager?.accessToken;

  constructor(private auth: AngularFireAuth) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.auth.currentUser).pipe(
      take(1),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(request);
        }

        return from(user.getIdToken()).pipe(
          take(1),
          exhaustMap((token) => {
            const modifiedRequest = request.clone({
              setHeaders: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            });

            return next.handle(modifiedRequest);
          })
        );
      })
    );

    // request = request.clone({
    //   setHeaders: {
    //     Authorization: this.token,
    //   },
    // });
    // console.log("Interceptor", request);
    // return next.handle(request);

    // return next.handle(request).pipe(
    //   tap(
    //     () => {},
    //     (err: any) => {
    //       if (err instanceof HttpErrorResponse) {
    //         if (err.status !== 401) {
    //           return;
    //         }
    //         this._authService.signOut();
    //       }
    //     }
    //   )
    // );
  }
}
