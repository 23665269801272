<ion-app>
  <ion-header *ngIf="this.activatedAppUrl !== '/auth/login'">
    <ion-toolbar *ngIf="this.activatedAppUrl?.includes('/manager/')">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="4">
            <ion-img class="staffing_logo" src="assets/pages/manager/dashboard/logo_staffingmanager.png"></ion-img>
          </ion-col>
          <ion-col size="8">
            <!-- <ion-img
							class="notification_icon"
							src="assets/pages/dashboard/icon_notifications.png"
						></ion-img> -->

            <ion-chip>
              <img src="../assets/pages/manager/dashboard/icon_downarrow.png" (click)="openUserPopover($event)" />
              <ion-label>{{ userDb?.full_name }}</ion-label>
              <ion-avatar>
                <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
              </ion-avatar>
            </ion-chip>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
    <ion-toolbar *ngIf="this.activatedAppUrl?.includes('/admin/')">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="4">
            <ion-img class="staffing_logo" src="assets/pages/admin/dashboard/logo_admin.png"></ion-img>
          </ion-col>
          <ion-col size="8">
            <ion-img class="notification_icon" src="assets/pages/dashboard/icon_notifications.png"
              (click)="openNotificationList($event)"></ion-img>
            <div *ngIf="(messageArray | async)?.length > 0" class="notification-badge">{{ (messageArray | async)?.length
              }}</div>

            <ion-chip>
              <img src="../assets/pages/manager/dashboard/icon_downarrow.png" (click)="openUserPopover($event)" />
              <ion-label>{{ userDb?.full_name }}</ion-label>
              <ion-avatar>
                <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
              </ion-avatar>
            </ion-chip>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>

  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <!-- Managers Menu -->
        <ion-list *ngIf="this.activatedAppUrl?.includes('/manager/')">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item id="i-list" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false"
              routerLinkActive="selected">
              <ion-img slot="start" [src]="this.activatedAppUrl === p.url ? p.selectedIcon : p.icon"></ion-img>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <!-- Admin Menu -->
        <ion-list *ngIf="this.activatedAppUrl?.includes('/admin/')">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of adminPages; let i = index">
            <!-- <ion-item id="i-list" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-img slot="start" [src]="this.activatedAppUrl === p.url ? p.selectedIcon : p.icon"></ion-img>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item> -->
            <ion-item [disabled]="p.disabled" id="i-list" routerDirection="root" [routerLink]="[p.url]" detail="false"
              routerLinkActive="selected" lines="full">
              <!-- <ion-img slot="start" [src]="this.activatedAppUrl === p.url ? p.selectedIcon : p.icon"></ion-img> -->
              <ion-icon slot="start" [name]="p.iconName" size="small"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>